import React from "react"
import PageTransition from "gatsby-plugin-page-transitions"

import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO/SEO"
import HeroImage from "../components/HeroImage/HeroImage"
import AboutUs from "../components/AboutUs/AboutUs"
import ContentWrapper from "../components/ContentWrapper/ContentWrapper"
import Story from "../components/Story/Story"

const IndexPage = ({ data }) => {
  return (
    <PageTransition
      defaultStyle={{
        transition: "opacity 500ms ease",
        opacity: "0",
      }}
      transitionStyles={{
        entered: { opacity: "1" },
      }}
      transitionTime={250}
    >
      <Layout>
        <SEO
          title="Home"
          description="Zrealizowane projekty"
          description="Świadczymy usługi consultingowo-prawne przy fuzjach firm i sprzedaży nieruchomości
    sektora medycznego. Zadbamy także o marketing Twojej placówki."
        />
        <div>
          <HeroImage />
          <ContentWrapper>
            {/* fast spacer */}
            <div style={{ height: "100px" }} id="about-us"></div>
            <AboutUs withButton />
          </ContentWrapper>
          <Story />
          <ContentWrapper>{/* <HomePageProjects /> */}</ContentWrapper>
        </div>
      </Layout>
    </PageTransition>
  )
}

export default IndexPage
